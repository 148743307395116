import React from 'react';
import ReactDOM from 'react-dom/client';
import ChatPage from './pages/chat';
import UploadPage from './pages/event-users/upload';
import NotFoundPage from './pages/not-found';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CssBaseline>
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' Component={ChatPage} /> */}
        {/* <Route path='/event-user/:link-hash' Component={MainPage} /> */}
        <Route path='/:linkHash' Component={ChatPage} />

        <Route path='/event-user/upload' Component={UploadPage} />

        <Route path="*" Component={NotFoundPage} />
      </Routes>
    </BrowserRouter>
  </CssBaseline>
);
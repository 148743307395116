import axios from 'axios'
import apiBaseUrl from "./apiConfig";

export const getRetriever = (data) => {
    return axios.post(`${apiBaseUrl}/retriever`, data)
}

export const getRetrieverStream = async (data) => {
    const response = await fetch(`${apiBaseUrl}/retriever`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
    }

    return response.body; // Return the body stream
};

export const getEventUser = (linkHash) => {
    return axios.get(`${apiBaseUrl}/event-users/${linkHash}`);
}

export const uploadEventUsers = (data) => {
    return axios.post(`${apiBaseUrl}/upload-event-users`, data)
}
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import './main-page.scss'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import MainLayout from '../../layouts/main'
import QuestionBar from '../../components/question-bar'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop'
import { getRetriever, getEventUser } from '../../utils/apiRoutes'
import AvatarContainer from '../../components/avatar';

const ChatPage = () => {
    const { linkHash } = useParams();
    const navigate = useNavigate();
    const [context, setContext] = useState([])
    const [isMessageSectionScrolledDown, setIsMessageSectionScrolledDown] = useState(false)
    const messagesSectionRef = useRef(null)
    const [modalOpen, setModalOpen] = useState(true);
    const [eventUser, setEventUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const isMobile = window.innerWidth < 1000;
    const [sessionId, setSessionId] = useState(new Date().getTime());

    const AvatarContainerRef = useRef(null);

    useLayoutEffect(() => {
        const fetchEventUser = async () => {
            try {
                const { data } = await getEventUser(linkHash);
                setEventUser(data);
            } catch (error) {
                navigate('/');
            }
        };

        fetchEventUser();
    }, [linkHash]);

    const ClientMessage = (id, message) => {
        return (
            <Grid key={id} style={{ alignSelf: 'flex-end', padding: '10px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#f5f5f5' }}>
                <p
                    style={{ margin: 0 }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </Grid>
        )
    }

    const SystemMessage = (id, message) => {
        return (
            <Grid key={id} style={{ alignSelf: 'flex-start', padding: '0px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#ffffff' }}>
                <p
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </Grid>
        )
    }

    const LoadingMessage = (id) => {
        return (
            <Grid key={id} style={{ alignSelf: 'flex-start', padding: '20px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#ffffff' }}>
                <div className='loading-dots'>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </div>
            </Grid>
        )
    }

    const removeLoadingMessage = () => {
        setContext(prevItems => prevItems.filter(item => item.user_type === 'loading'));
    }

    const renderTextMessage = (id, user_type, message) => {
        if (user_type === 'client')
            return ClientMessage(id, message)

        if (user_type === 'system')
            return SystemMessage(id, message)

        if (user_type === 'loading')
            return LoadingMessage(id)
    }

    const renderGraphic = (graphic_type, data) => {
        return (
            <ResponsiveContainer width="100%" aspect={2}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    const scrollDownMessages = () => {
        messagesSectionRef.current.scrollTo({
            top: messagesSectionRef.current.scrollHeight,
            behavior: 'smooth'
        })
        setIsMessageSectionScrolledDown(true)
    }

    // const onScroll = ({ target }) => {
    //     if ((target.scrollHeight - target.scrollTop) === target.clientHeight) {
    //         setIsMessageSectionScrolledDown(false)
    //     } else if (isMessageSectionScrolledDown) {
    //         setIsMessageSectionScrolledDown(true)
    //     }
    // }

    // useEffect(() => {
    //     messagesSectionRef.current.onscroll = onScroll
    // }, [isMessageSectionScrolledDown])

    useEffect(() => {
        const lastMessage = context[context.length - 1]

        if (context.length && lastMessage.user_type === 'client') {
            setLoading(true);
            handleRetriever(lastMessage);
        }

        scrollDownMessages()
        // console.log("context", context)
    }, [context])

    async function handleRetriever(lastMessage) {
        const newContext = [...context, { message_type: 'text', user_type: 'loading', message: '', id: context.length }]
        setContext(newContext)
        try {
            const isUsingAvatar = AvatarContainerRef.current?.isAvatarVisible();

            const { data } = await getRetriever({
                'sentence': lastMessage.message,
                'context': context,
                'linkHash': linkHash,
                'usingAvatar': isUsingAvatar,
                'sessionId': sessionId
            });

            if (isUsingAvatar) {
                await AvatarContainerRef.current.talkButton(data[0].avatar_response);
            }

            setTimeout(() => {
                removeLoadingMessage()
                const newContext = [...context, { message_type: 'text', user_type: 'system', message: data[0].response, id: context.length }];
                setContext(newContext);
                setLoading(false);
            }, isUsingAvatar ? 2500 : 0)

        } catch (error) {
            console.error("Erro ao recuperar dados:", error);
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    };

    const buttonContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        marginTop: '16px'
    };

    const startChat = () => {
        setLoading(true);
        const newContext = [...context, { message_type: 'text', user_type: 'loading', message: '', id: context.length }]
        setContext(newContext)

        let quoteProductsList = eventUser.event_data['quote_products'];
        quoteProductsList = quoteProductsList.replace(' E ', ', ');

        let firstMessage = `Olá, eu sou o ${eventUser.name} e meus produtos de interesse são: ${quoteProductsList}`

        getRetriever({
            'sentence': firstMessage,
            'context': context,
            'linkHash': linkHash,
            'usingAvatar': false,
            'sessionId': sessionId
        }).then(({ data }) => {
            removeLoadingMessage();
            const newContext = [...context, { message_type: 'text', user_type: 'system', message: data[0].response, id: context.length }]
            setContext(newContext)
            setLoading(false);
        })

    }

    const handleModalOption = (option) => {
        if (option === 'start') {
            setTimeout(() => {
                startChat();
            }, 500);
        }

        setModalOpen(false);
    }

    return (
        <MainLayout>
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    onClick: (event) => event.stopPropagation(),
                    timeout: 500,
                }}
                disableEscapeKeyDown
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Bem vindo(a), {eventUser ? eventUser.name : ''}!
                    </Typography>
                    <Box sx={buttonContainerStyle}>
                        <Button variant="outlined" color="error" disabled={!eventUser} onClick={() => { handleModalOption('start') }}>Iniciar Chat</Button>
                    </Box>
                </Box>
            </Modal>
            <AvatarContainer
                ref={AvatarContainerRef}
                eventUser={eventUser}
                modalOpen={modalOpen}
            />
            <Grid container item direction="column" wrap="nowrap" xs={isMobile ? 8 : 6} className="messages-container">
                <Box style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }} />
                <Grid container direction="column" wrap="nowrap" className="messages-section" gap={4} ref={messagesSectionRef} style={{
                    height: 'auto',
                    overflowY: 'auto',
                    scrollbarWidth: 'none'
                }}>
                    {
                        context.map(({ message, user_type, message_type, graphic_type, id, data }) => {
                            return message_type === 'text' ?
                                renderTextMessage(id, user_type, message)
                                : renderGraphic(graphic_type, data)
                        })
                    }
                </Grid>
                {!isMessageSectionScrolledDown ?
                    <Button variant="outlined" style={{
                        backgroundColor: "#fff",
                        position: "fixed",
                        bottom: "100px",
                        width: "fit - content",
                        alignSelf: "center",
                        borderRadius: "25px"
                    }} onClick={scrollDownMessages}>Ir para o fim</Button>
                    : null}
                <QuestionBar context={context} setContext={setContext} loading={loading} />
            </Grid>
        </MainLayout >
    )
}

export default ChatPage;